<template>
  <div class="vx-row queues-table" id="queues-table">
    <vs-col vs-w="12">
      <div class="pt-4 pb-base vs-con-loading__container">
        <vs-table
          :search="true"
          :hoverFlat="true"
          @mouseover="() => undefined"
          @mouseup="() => undefined"
          :data="queues"
          :max-items="15"
          ref="table"
          :no-data-text="lang.general.noDataText[languageSelected]"
        >
          <!-- pagination
            @change-page="handleChangePage"
            :total="total"-->
          <template slot="header">
            <vs-button
              v-if="isAuthAux('konecta.queues.add')"
              id="btn-create-queue"
              class="float-right mb-3"
              @click="() => SET_QUEUE()"
            >
              {{
                lang.queues.table.addQueue[languageSelected]
              }}
            </vs-button>
          </template>
          <template slot="thead">
            <vs-th vs-w="1" key="queue">{{
              lang.queues.title[languageSelected]
            }}</vs-th>
            <vs-th key="queueDescription">{{
              lang.botMaker.testGroups.view.description[languageSelected]
            }}</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr
              :data="indextr"
              :key="indextr"
              v-for="(tr, indextr) in data"
              class="bg-white"
              height="60px"
            >
              <vs-td :data="data[indextr].name">
                {{ data[indextr].name }}
              </vs-td>
              <vs-td :data="data[indextr].description">
                {{ data[indextr].description }}
              </vs-td>
              <vs-td>
                <div>
                  <ul class="horizontal-nav">
                    <li
                      class="navbar-item cursor-pointer"
                      @click="openQueueDetail(data[indextr]._id)"
                      v-if="isAuthAux('konecta.queues.edit')"
                      :id="`edit-btn-${indextr}`"
                    >
                      <feather-icon
                        icon="EditIcon"
                        class="inline-block mr-2"
                        svgClasses="w-8 h-8"
                      />
                    </li>
                    <li
                      class="navbar-item cursor-pointer"
                      @click="deleteQueueConfirmation(data[indextr]._id)"
                      v-if="isAuthAux('konecta.queues.delete') && !data[indextr].isDefaultQueue"
                      :id="`delete-btn-${indextr}`"
                    >
                      <feather-icon
                        icon="TrashIcon"
                        class="inline-block mr-2"
                        svgClasses="w-8 h-8"
                      />
                    </li>
                  </ul>
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <!-- <vs-pagination
            class="mt-2 float-right"
            v-if="testGroups.length"
            :value="1"
            :total="Math.ceil(testGroups.length / 15)"
          /> -->
      </div>
    </vs-col>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import staffManagementMixin from '@/mixins/staffManagementMixin'

export default {
  name: 'queueList',
  mixins: [staffManagementMixin],
  components: {
    VsTable: () => import('@/components/vx-table/vsTable'),
    VsTh: () => import('@/components/vx-table/vsTh'),
    VsTr: () => import('@/components/vx-table/vsTr'),
    VsTd: () => import('@/components/vx-table/vsTd')
    // EmptyTable: () => import('../../components/EmptyTable.vue'),
  },
  props: {
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'session']),
    ...mapGetters('queues', ['queues', 'selectedQueue'])
  },
  methods: {
    ...mapActions('queues', ['SET_QUEUE', 'DELETE_QUEUE']),
    openQueueDetail(queueId) {
      this.$router.push({
        path: '/queues',
        query: {
          queueId: queueId
        }
      })
      this.SET_QUEUE(queueId);
    },
    async deleteQueue(queueId) {
      try{
        this.$vs.loading()
        await this.DELETE_QUEUE(queueId)
        this.$vs.notify({
            title: this.lang.queues.table.deleteQueue.success.title[this.languageSelected],
            text: this.lang.queues.table.deleteQueue.success.text[this.languageSelected],
            color: 'success'
        })
      } catch (ex) {
          this.$vs.notify({
            title: this.lang.queues.table.deleteQueue.error.title[this.languageSelected],
            text: this.lang.queues.table.deleteQueue.error.text[this.languageSelected],
            color: 'danger'
          })
      }
      this.$vs.loading.close()
    },
    deleteQueueConfirmation(queueId) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: this.lang.queues.table.queueDeletionTitle[
          this.languageSelected
        ],
        text: this.lang.queues.table.confirmQueueDeletion[this.languageSelected],
        accept: () => this.deleteQueue(queueId) 
      })
    }
  },
  async mounted() {}
}
</script>

<style lang="scss">
#queue {
  .title {
    margin-bottom: 20px;
  }
}
</style>

<style>
.vs-table--tbody {
  z-index: 1 !important;
  min-width: 0% !important;
}

.vs-table--tbody-table {
  min-width: 0% !important;
}
</style>

<style scoped>
.center {
  margin: auto;
  width: 100%;
}
</style>

<style lang="scss">
.queues-table {
  margin: 30px 0px;
  padding: 20px;
  background-color: #fafafa;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  #btn-create-queue {
    margin-left: 0px !important;
    margin-right: auto;
  }
  .error-message {
    display: block;
    height: 19px;
    color: rgba(var(--vs-danger), 1);
    font-size: 0.65rem;
  }
  .feather {
    width: 16px !important;
    height: 16px !important;
  }
  h2 {
    font-size: 18px;
    margin-bottom: 30px;
  }
  .btn-actions {
    display: flex;
    justify-content: flex-end;
  }

  .vs-pagination--buttons,
  .item-pagination {
    width: 30px;
    height: 30px;
  }
  .item-pagination {
    font-size: 0.8rem;
  }

  .con-vs-pagination {
    padding: 7px;
  }
  .horizontal-nav {
    display: flex;
    float: right;
    margin-right: 0px;
    .navbar-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 5px 10px;

      .con-vs-tooltip {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .feather-icon {
          transition: all 0.3s;
          .feather {
            width: 20px !important;
            height: 20px !important;
          }

          &:hover,
          &.active {
            transform: scale(1.2);
            color: rgba(var(--vs-primary), 1) !important;
          }
        }

        .material-icons,
        .material-icons-outlined {
          font-size: 24px;
          transition: all 0.3s;
          &:hover,
          &.active {
            transform: scale(1.2);
            color: rgba(var(--vs-primary), 1) !important;
          }
        }
      }
    }
  }
}
</style>